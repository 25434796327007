var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.createdAt)))]}},{key:"item.activeDate",fn:function(ref){
var item = ref.item;
return [(item.activeDate)?_c('span',[_vm._v(_vm._s(_vm.stringFormatDate(item.activeDate)))]):_c('span',[_vm._v("Not Activated")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(JSON.stringify(item._id) === _vm.activeId)?_c('span',[_vm._v("Active")]):(JSON.stringify(item._id) === _vm.editingId)?_c('span',[_vm._v("Editing")]):_vm._e()]}},{key:"item.submissions",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.submissions || 0))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.actions),function(ref,index){
var icon = ref.icon;
var text = ref.text;
var subtext = ref.subtext;
var action = ref.action;
return _c('v-list-item',{key:'action-' + index,on:{"click":function($event){return _vm.doAction(item, action)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(subtext))])],1)],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }