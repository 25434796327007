<template>
  <v-card>
    <v-card-text>

    </v-card-text>
    <v-data-table :headers="headers" :items="items" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
      <template v-slot:item.createdAt="{ item }">{{ stringFormatDate(item.createdAt) }}</template>
      <template v-slot:item.activeDate="{ item }">
        <span v-if="item.activeDate">{{ stringFormatDate(item.activeDate) }}</span>
        <span v-else>Not Activated</span>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="JSON.stringify(item._id) === activeId">Active</span>
        <span v-else-if="JSON.stringify(item._id) === editingId">Editing</span>
      </template>
      <template v-slot:item.submissions="{ item }">{{ item.submissions || 0 }}</template>
      <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="({ icon, text, subtext, action }, index) in item.actions" :key="'action-' + index" @click="doAction(item, action)">
              <v-list-item-action>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-subtitle>{{ subtext }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  props: {
    formId: String,
    accessRole: String
  },
  setup (props, { root, emit }) {
    const headers = ref([
      { text: 'Version', value: 'version' },
      { text: 'Created Date', value: 'createdAt' },
      // { text: 'Active Date', value: 'activeDate' },
      { text: 'Current Status', value: 'status' },
      { text: 'Submissions', value: 'submissions' },
      { text: 'Actions', value: 'actions' }
    ])
    const items = ref([])

    const activeId = ref('')
    const editingId = ref('')

    onMounted(() => {
      // Load the active/editing IDs from the forms/base service
      root.$feathers.service('forms/base').get(props.formId).then((data) => {
        activeId.value = 'active' in data.revisions ? JSON.stringify(data.revisions.active) : ''
        editingId.value = 'editing' in data.revisions ? JSON.stringify(data.revisions.editing) : ''
      })
      // Load all of the revisions from the forms/revision service
      loadRevisions()
    })

    async function loadRevisions () {
      items.value = []
      const aggregate = [
        { $match: { form: props.formId } },
        { $project: { _id: 1, version: 1, activeDate: 1, createdAt: 1, submissions: 1 } }
      ]
      const { data } = await root.$feathers.service('forms/revision').find({ query: { aggregate } })
      data.forEach((row) => {
        row.actions = [{ icon: 'fal fa-eye', action: 'view', text: 'View this form version', subtext: 'Opens in a new tab' }]
        let id = JSON.stringify(row._id)
        if (id !== activeId && (props.accessRole === 'Admin' || props.accessRole === 'Editor')) {
          row.actions.push({ icon: 'fal fa-check', action: 'activate', text: 'Make this revision active', subtext: 'Makes this revision the default version' })
        }
        if (id !== editingId && (props.accessRole === 'Admin' || props.accessRole === 'Editor')) {
          row.actions.push({ icon: 'fal fa-pencil', action: 'edit', text: 'Edit this revision', subtext: 'Makes this revision the active editing version' })
        }
        if (row.submissions === 0 && id !== activeId.value && id !== editingId.value && (props.accessRole === 'Admin' || props.accessRole === 'Editor')) {
          row.actions.push({ icon: 'fal fa-trash', action: 'remove', text: 'Delete this revision', subtext: 'Only available if no submissions and not active' })
        }
        items.value.push(row)
      })
    }

    async function doAction (item, action) {
      // console.log(action, item)
      switch (action) {
        case 'view':
          window.open('/forms/' + props.formId + '/' + item._id)
          break
        case 'activate':
          let data = await root.$feathers.service('forms/base').get(props.formId)
          let patch = { 'revisions.active': item._id }
          if (!data.active) {
            patch.active = true
          }
          root.$feathers.service('forms/base').patch(props.formId, patch)
          activeId.value = JSON.stringify(item._id)
          break
        case 'edit':
          // console.log('edit', item)
          if (props.accessRole === 'Admin' || props.accessRole === 'Editor') {
            root.$feathers.service('forms/base').patch(props.formId, { 'revisions.editing': item._id }).then((data) => {
              root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision set as active editing revision' })
              if ('revisions' in data) {
                activeId.value = 'active' in data.revisions ? JSON.stringify(data.revisions.active) : ''
                editingId.value = 'editing' in data.revisions ? JSON.stringify(data.revisions.editing) : ''
              }
              // loadRevisions()
              emit('changeEditRevision', { revisionId: item._id })
            })
          } else {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have access to do this. Please contact a form admin or editor to do this action.' })
          }
          break
        case 'remove':
          if (props.accessRole === 'Admin' || props.accessRole === 'Editor') {
            // Make sure the form does not have any submissions
            root.$feathers.service('forms/submission').find({ query: { 'form.base': props.formId, 'form.revision': item._id, $limit: 0 } }).then(({ total }) => {
              if (total === 0) {
                root.$feathers.service('forms/revision').remove(item._id).then(() => {
                  root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form revision was removed successfully' })
                  emit('revisionRemoved')
                  loadRevisions()
                })
              } else {
                root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Cannot remove revision: ' + total + ' submission' + (total !== 1 ? 's exist' : ' exists') + ' for it' })
              }
            })
          } else {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have access to do this. Please contact a form admin or editor to do this action.' })
          }
          break
      }
    }

    return {
      headers,
      items,
      activeId,
      editingId,
      doAction,
      stringFormatDate
    }
  }
}
</script>
